<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3">
        <v-row>
          <v-col cols="12">
            <v-card outlined id="system-actions">
              <v-card-title class="my-0"> <v-icon left color="grey">dns</v-icon> System Actions </v-card-title>
              <v-card-text class="my-0 py-0">
                <v-list dense class="my-0 py-0">
                  <v-list-item style="height: 30px" @click="directNewDocumentation()">
                    <v-list-item-action>
                    <v-icon>launch</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                       Go to new documentation 
                      </v-list-item-title>
                    </v-list-item-content>
                   
                  </v-list-item>
                  <v-list-item style="height: 30px">
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" v-if="shipment.fileStatus == 'Pending'"
                            color="orange">schedule</v-icon>
                          <v-icon v-bind="attrs" v-on="on" v-else-if="shipment.fileStatus == 'In Progress'"
                            color="blue">pending</v-icon>
                          <v-icon v-bind="attrs" v-on="on" v-else-if="shipment.fileStatus == 'Complete'"
                            color="green">task_alt</v-icon>
                        </template>
                        <span style="font-size: 12px">{{
                          shipment.fileStatus
                          }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('documentCreationStatus') }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="shipment.documentationStatus == 'OPEN'">
                      <v-tooltip top v-if="!shipment.fileLocked && shipment.fileStatus == 'Complete'">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" @click="refreshShipmentDocuments" icon><v-icon>refresh</v-icon></v-btn>
                        </template>
                        <span style="font-size: 12px">Regenerates all documents for file & resets all
                          tasks</span>
                      </v-tooltip>
                      <v-tooltip top v-else-if="shipment.fileLocked">
                        <template v-slot:activator="{ on }"><v-icon v-on="on" color="amber">lock</v-icon>
                        </template>
                        <span style="font-size: 12px">File sync is locked, changes must be made via the
                          container.</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item style="height: 30px">
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" v-if="shipment.taskStatus == 'Pending'"
                            color="orange">schedule</v-icon>
                          <v-icon v-bind="attrs" v-on="on" v-else-if="shipment.taskStatus == 'In Progress'"
                            color="blue">pending</v-icon>
                          <v-icon v-bind="attrs" v-on="on" v-else-if="shipment.taskStatus == 'Complete'"
                            color="green">task_alt</v-icon>
                        </template>
                        <span style="font-size: 12px">{{
                          shipment.taskStatus
                          }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Task Creation Status
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item style="height: 30px" v-if="shipment.documentationStatus == 'OPEN'">
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="green">task_alt</v-icon>
                        </template>
                        <span style="font-size: 12px">Complete</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Reset all Tasks
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-tooltip top v-if="shipment.shipmentFile.synced && !shipment.fileLocked">
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" :disabled="shipment.taskStatus != 'Complete'" @click="resetAllTasks"
                            :loading="resettingTasks" icon><v-icon>refresh</v-icon></v-btn>
                        </template>
                        <span style="font-size: 12px">Resets all shipment tasks.</span>
                      </v-tooltip>
                      <v-tooltip top v-else-if="shipment.fileLocked">
                        <template v-slot:activator="{ on }"><v-icon v-on="on" color="amber">lock</v-icon>
                        </template>
                        <span style="font-size: 12px">File sync is locked, changes must be made via the
                          container.</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <!-- <v-list-item style="height: 30px"  @click="hookShipment()">
                    <v-list-item-content>
                      <v-list-item-title>
                        Sync to finance
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                     <v-progress-circular color="primary" size="24" v-if="hookingShipment" indeterminate></v-progress-circular>
                    </v-list-item-action>
                  </v-list-item> -->

                  <v-list-item style="height: 30px" v-for="relationship in relationships" :key="relationship.id"
                    :loading="savingInvoiceStatus">
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"
                            :color="getInvoiceColor(relationship.invoiceStatus)">{{getInvoiceIcon(relationship.invoiceStatus)}}</v-icon>
                        </template>
                        <span style="font-size: 12px">{{
                          getInvoiceName(relationship.invoiceStatus)
                          }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Invoice Status
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="relationship.customer" style="font-size: 12px">
                        {{ relationship.customer.name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltipOn }">
                              <v-btn icon small style="text-transform: none" v-bind="attrs"
                                v-on="{ ...on, ...tooltipOn }" :loading="savingInvoiceStatus">
                                <v-icon>expand_more</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">Update Invoice Status</span>
                          </v-tooltip>
                        </template>
                        <v-list dense>
                          <v-list-item v-for="(invoice, index ) in invoiceStatusOptions" :key="index"
                            :style="{ 'border-left': `5px solid ${invoice.color}` }"
                            @click="relationship.invoiceStatus = invoice.value, updateInvoiceStatus(relationship)"
                            style="height: 30px">
                            <v-list-item-content class="text-left ml-0 pl-0">
                              <v-list-item-title>{{ invoice.name }}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
            <v-card id="overview-events" outlined :loading="loadingCustomEvents">
              <v-card-title class="my-0">
                <v-icon left color="grey">event</v-icon>
                Events
                <v-spacer></v-spacer>
                <v-tooltip top v-if="shipment.documentationStatus == 'OPEN'">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" text @click="addCustomEvent">
                      <v-icon>add_circle_outline</v-icon>
                    </v-btn>
                  </template>
                  Add Custom Event
                </v-tooltip>
              </v-card-title>
              <v-card-text class="my-0 py-0">
                <v-list dense>
                  <v-list-item v-if="events.length == 0 && customEvents.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No available events</span>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-for="event in events" :key="event.id" @click="viewEvent(event)">
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon class="mr-2" v-on="on" :color="getStatus(event.status).color">{{
                            getStatus(event.status).icon }}</v-icon>
                        </template>
                        <span>{{ event.status }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ event.title }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="event.reference">
                        {{ event.reference }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item v-for="event in customEvents" :key="event.id" @click="editCustomEvent(event)">
                    <v-list-item-action>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-icon class="mr-2" v-on="on" :color="getStatus(event.status).color">{{
                            getStatus(event.status).icon }}</v-icon>
                        </template>
                        <span>{{ event.status }}</span>
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ event.shipmentCustomEventType.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="event.reference">
                        {{ event.reference }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                </v-list>
              </v-card-text>
            </v-card>
            <v-card outlined id="special-instructions">
              <v-card-title class="my-0"> <v-icon left color="amber">star</v-icon> Special Instructions </v-card-title>
              <v-card-text class="my-0 py-0">
                <v-list dense class="my-0 py-0">
                  <v-list-item v-for="(special) in documentSpecialComments" :key="special.id">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ special.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="special.comment" class="text-wrap" style="font-size: 12px">
                        <b> Comment: </b> {{ special.comment }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-wrap" style="font-size: 12px" v-if="special.specialInstruction">
                        <b>Special Note: </b> {{ special.specialInstruction }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="documentSpecialComments.length == 0">
                    <v-list-item-content class="text-center">
                      <span style="color: grey">No Special Instructions or comments</span>
                    </v-list-item-content></v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card outlined id="customer-management" v-for="relationship in relationships" :key="relationship.id">
          <v-card-title class="my-0">
            <v-icon left color="grey">groups</v-icon>
            Customer Management
            <v-spacer></v-spacer>
            <v-chip :color="getInvoiceColor(relationship.invoiceStatus)" small>{{
              getInvoiceName(relationship.invoiceStatus) }}</v-chip>
          </v-card-title>
          <v-card-text>
            <v-card flat
              :style="{ 'background-color': relationship.organisationRelationship && relationship.organisationRelationship.clientStatus == 'On Hold' ? 'red !important' : 'transparent !important' }">
              <v-card-title style="font-size: 14px">
                <v-avatar size="28" v-if="relationship.customer.logo" class="mr-2" style="background: white">
                  <v-img contain :src="relationship.customer.logo"></v-img>
                </v-avatar>
                {{ relationship.customer.name }} <v-spacer></v-spacer>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon
                      @click="routeToContact(relationship.organisationRelationship)"><v-icon>launch</v-icon></v-btn>

                  </template>
                  View Profile
                </v-tooltip>
              </v-card-title>
              <v-card-subtitle v-if="relationship.organisationRelationship" style="font-size: 12px">
                Client status: {{ relationship.organisationRelationship.clientStatus }} <br>
                Account Reference: {{ relationship.organisationRelationship.accountReference }} <br>
                Contract Owner : {{
                shipment.incoTermContractOwner === 'buyer' &&shipment.buyer ? shipment.buyer.name :
                shipment.incoTermContractOwner === 'shipper' && shipment.shipper ? shipment.shipper.name :
                shipment.incoTermContractOwner === 'forwarder' && shipment.forwarder ? shipment.forwarder.name :
                shipment.incoTermContractOwner === 'consignee' && shipment.consignee ? shipment.consignee.name :
                ''
                }} <br>
                SL Freight Invoice To: {{
                shipment.freightCharge === 'buyer' &&shipment.buyer ? shipment.buyer.name :
                shipment.freightCharge === 'shipper' && shipment.shipper ? shipment.shipper.name :
                shipment.freightCharge === 'forwarder' && shipment.forwarder ? shipment.forwarder.name :
                shipment.freightCharge === 'consignee' &&shipment.consignee ? shipment.consignee.name :
                ''

                }}
                <br>
                <div v-if="shipment.freightInvoiceTo && shipment.freightInvoiceTo != ''">
                  FF Freight Invoice To: {{
                  shipment.freightInvoiceTo }}</div>
                <div v-if="relationship.invoiceStatus == 'invoiced'">
                  Invoice Processed By: {{
                  relationship.invoicedBy }}</div>

              </v-card-subtitle>
            </v-card>
          </v-card-text>
        </v-card>

        <v-card id="service-failures" flat outlined>
          <v-card-title class="my-0">
            <v-icon left color="grey">cancel</v-icon>
            Service Failures
            <v-spacer></v-spacer>
            <v-tooltip top v-if="shipment.documentationStatus == 'OPEN'">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" color="primary" text @click="addServiceFailure">
                  <v-icon>add_circle_outline</v-icon>
                </v-btn>
              </template>
              Add General Service Failure
            </v-tooltip>
          </v-card-title>
          <v-card-text class="ma-0 pa-0">
            <v-progress-linear color="primary" indeterminate v-if="loadingServiceFailures"></v-progress-linear>
            <v-list dense class="my-0 py-0 mx-0 px-0" style="max-height:34.5vh;overflow-y:auto">
              <v-list-item v-for="failure in serviceFailures" :key="failure.id" @click="editServiceFailure(failure)">
                <v-list-item-content>
                  <v-list-item-title v-if="failure.shipmentDocumentType">
                    {{ failure.shipmentDocumentType.locationDocument.name }}
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    General Service Failure
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-icon color="grey" class="mr-2" small>category</v-icon>
                    {{ failure.serviceFailureCategory.name }} <span v-if="failure.serviceFailureSubcategory">: {{
                      failure.serviceFailureSubcategory.name }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="failure.createdBy" class="wrap-text">
                    <v-icon color="grey" small class="mr-2">person</v-icon> {{ failure.createdBy.firstname }} {{
                    failure.createdBy.surname }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="failure.responsibleParty || failure.responsibleOrganisation"
                    class="wrap-text">
                    <v-icon color="grey" small class="mr-2">business</v-icon> <span
                      v-if="failure.responsibleOrganisation">{{ failure.responsibleOrganisation.name }}</span>
                    <span v-else>Other: {{ failure.responsibleParty }}</span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text">
                    <v-icon color="grey" small class="mr-2">event</v-icon>{{ formatDate(failure.createdAt) }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="failure.comment" class="wrap-text">
                    <v-icon color="grey" small class="mr-2">chat</v-icon> {{ failure.comment }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="!loadingServiceFailures && serviceFailures && serviceFailures.length == 0">
                <v-list-item-content class="text-center">
                  <span style="color: grey">No service failures.</span>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card outlined id="account-summary">
          <v-card-title> <v-icon color="grey" class="mr-2">account_balance</v-icon> Account Summary
            <v-spacer></v-spacer><v-btn icon
              @click="getBillableDocuments()"><v-icon>refresh</v-icon></v-btn></v-card-title>
          <v-card-text class="my-0 py-0">
            <v-tabs v-model="activeBillingTab">
              <v-tab href="#document">
                Documents
              </v-tab>
              <v-tab href="#additional" v-if="billableEvents() && billableEvents().length > 0">Additional <v-chip
                  class="ml-2" small>{{
                  billableEvents().length }}</v-chip> </v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeBillingTab">
              <v-tab-item value="document" style="color: var(--v-background-base) !important">
                <v-card flat class="ma-0 pa-0">
                  <v-card-text class="ma-0 pa-0">
                    <v-list dense style="max-height: 52vh; overflow-y:auto">
                      <v-progress-linear v-if="loadingBillableDocuments" indeterminate
                        color="primary"></v-progress-linear>
                      <v-list-item v-for="account in accountDocuments" :key="account.id">
                        <v-list-item-action>
                          <v-chip>{{ account.count }}</v-chip>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title class="ml-0 pl-0">
                            {{ account.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>

              </v-tab-item>
              <v-tab-item value="additional">
                <v-card flat class="ma-0 pa-0">
                  <v-card-text class="ma-0 pa-0" style="max-height: 52vh; overflow-y:auto">
                    <v-list dense v-for="additional in billableEvents()" :key="additional.id">
                      <div>
                        <v-subheader style="font-size: 16px">{{ additional.name }}</v-subheader>
                        <v-list-item style="height: 20px" v-for="status in additional.statuses" :key="status.id">
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ status.name }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-chip>
                              {{ status.count }}
                            </v-chip>
                          </v-list-item-action>
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6" sm="3">
        <v-card outlined :loading="loadingComments" id="shipment-overview-comments">
          <v-card-title class="my-0">
            <v-icon left color="grey">insights</v-icon>
            Actions
          </v-card-title>
          <v-card-text class="my-0 py-0">
            <v-list dense class="my-0 py-0">
              <v-list-item @click="processChange('CHANGE_DESTINATION')" style="height: 40px">
                <v-list-item-action>
                  <v-icon color="blue">
                    mode_of_travel
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  Change Destination
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="processChange('CHANGE_PARTY')" style="height: 40px">
                <v-list-item-action>
                  <v-icon color="teal">
                    groups
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  Change Party
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="processChange('CHANGE_PROFILE')" style="height: 40px">
                <v-list-item-action>
                  <v-icon color="orange">
                    handshake
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  Change Profile
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="processChange('CHANGE_VESSEL')" style="height: 40px">
                <v-list-item-action>
                  <v-icon color="blue-grey lighten-1">
                    directions_boat
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  Change Vessel
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="processChange('MOVE_CONTAINER')" style="height: 40px">
                <v-list-item-action>
                  <v-icon color="amber darken-1">
                    swap_horiz
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  Move Container(s)
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="processChange('SHORT_SHIPMENT')" style="height: 40px">
                <v-list-item-action>
                  <v-icon color="red darken-1">
                    warning
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  Short Shipment
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="processChange('CHANGE_SHIPPING_LINE')" style="height: 40px">
                <!--add @click button function-->
                <v-list-item-action>
                  <v-icon color="purple">
                    hub
                  </v-icon>
                </v-list-item-action>
                <v-list-item-title>
                  Change Shipping Line
                </v-list-item-title>
              </v-list-item>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on" @click="changeShipmentUCRModal = true" style="height: 40px">
                    <v-list-item-action>
                      <v-icon :color="shipment.ucrNo ? 'green' : 'grey'">tag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Change UCR Number </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>{{shipment.ucrNo ?? '- - - - - - - -'}}</span>
              </v-tooltip>
            </v-list>
          </v-card-text>
        </v-card>
        <!-- <v-card outlined id="overview-documents">
          <v-card-title class="my-0">
            <v-icon left color="grey">description</v-icon>
            Documents
            <v-spacer></v-spacer>
            <v-chip color="orange" class="mx-1" outlined v-if="warningCount > 0">
              <v-icon left>warning</v-icon>
              {{ warningCount }}
            </v-chip>
            <v-chip color="red" class="mx-1" outlined v-if="dangerCount > 0">
              <v-icon left>error</v-icon>
              {{ dangerCount }}
            </v-chip>
          </v-card-title>
          <v-card-text class="my-0 py-0">
            <v-list dense style="max-height: 57vh; overflow-y:auto">
              <v-list-item v-if="shipment.fileStatus != 'Complete'">
                <v-list-item-content class="text-center">
                  <span style="color: grey">Documents are still being created.</span>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-for="document in summarisedDocuments" :key="document.id"
                :style="{ 'border-left': documentDanger(document) ? '5px solid red' : documentWarning(document) ? '5px solid orange' : '' }"
                class="mt-1">
                <v-list-item-content class="text-wrap">
                  <v-list-item-title>
                    {{ document.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Submit: {{ document.submittedDue }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    Complete: {{ document.completedDue }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content>
                  <v-row justify="center" class="ml-1">
                    <v-tooltip top v-for="status in document.statuses" :key="status.index">
                      <template v-slot:activator="{ on }">
                        <v-chip v-on="on" class="mx-1 my-1" small pill outlined :color="getStatusColor(status.status)">
                          <v-icon small left>{{
                            getStatusIcon(status.status)
                          }}</v-icon>
                          {{ status.count }}
                        </v-chip>
                      </template>
                      {{ status.status }}
                    </v-tooltip>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card> -->
      </v-col>
    </v-row>

    <v-dialog v-model="changeShipmentUCRModal" max-width="30vw" persistent>
      <v-card>
        <v-toolbar flat>
          <v-card-title>Update UCR Number</v-card-title>
          <v-spacer></v-spacer>
          <v-btn :loading="savingShipmentUCR" text @click="saveShipmentUCR()"
            color="primary"><v-icon>save</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field v-model="shipment.ucrNo" dense outlined small label="UCR Number"></v-text-field>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="eventModal" width="1000px" :fullscreen="$vuetify.breakpoint.mobile">
      <EventModal :event="event" :documents="uploads" :shipment="shipment" @close="eventModal = false"
        @update="updateEvent" @refreshEvents="refreshEvents" :allowDelete="true" />
    </v-dialog>

    <v-dialog v-model="customEventModal" persistent width="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent"><v-toolbar-title>Create Custom Event</v-toolbar-title> <v-spacer></v-spacer>
          <v-btn id="info-guide" @click="csStartGuide" fab height="4vh" width="4vh">
            <v-icon>help</v-icon>
          </v-btn>
          <v-btn id="event-save" color="primary" icon :disabled="validateCustomEvent" :loading="savingCustomEvent"
            @click="saveCustomEvent"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="customEventModal = false, customEventItem = {}">X</v-btn></v-toolbar>
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete id="event-type" label="Event Type*" :items="customEventTypes"
                v-model="customEventItem.shipmentCustomEventTypeId" outlined dense clearable item-text="description"
                item-value="id" prepend-icon="format_list_bulleted"></v-autocomplete>
              <v-autocomplete id="event-status" :items="availableParties" v-model="customEventItem.courier"
                label="Courier To" outlined dense v-if="customEventItem.shipmentCustomEventTypeId == 7" clearable
                item-text="name" item-value="value" prepend-icon="local_shipping"></v-autocomplete>
              <v-autocomplete id="event-adress" :items="orgAdresses" v-model="customEventItem.destinationAddressId"
                label="Courier Address" outlined dense v-if="customEventItem.courier && orgAdresses.length >= 1"
                clearable item-text="fullAdress" item-value="id" prepend-icon="location_on"></v-autocomplete>
              <v-autocomplete id="event-status"
                v-if="customEventItem.shipmentCustomEventTypeId && customEventItem.shipmentCustomEventTypeId != 7"
                :items="customEventStatuses" v-model="customEventItem.status" label="Event Status" outlined dense
                clearable item-text="name" item-value="value" prepend-icon="star_outline"></v-autocomplete>

              <!-- <v-menu v-model="menuSubmitted" :close-on-content-click="false" :nudge-right="40" v-if="customEventItem.shipmentCustomEventTypeId == 7 && customEventItem.status== 'Submitted' || customEventItem.status=='Resubmitted' || customEventItem.status =='Completed'">
                  <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="customEventItem.submitted"
                          label="Submitted Date"
                          outlined dense
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="event"
                          :required="customEventItem.status == 'Submitted' || customEventItem.status=='Resubmitted' || customEventItem.status =='Completed'"
                      ></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.submitted" @input="menuSubmitted = false"></v-date-picker>
              </v-menu>
              <v-menu v-model="menuResubmitted" :close-on-content-click="false" :nudge-right="40" v-if="customEventItem.shipmentCustomEventTypeId == 7 && customEventItem.status=='Resubmitted'">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="customEventItem.resubmitted"
                        label="Resubmitted Date"
                        outlined dense
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="event"
                        :required="customEventItem.status=='Resubmitted'"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="customEventItem.resubmitted" @input="menuResubmitted = false"></v-date-picker>
            </v-menu>
              <v-menu v-model="menuCompleted" :close-on-content-click="false" :nudge-right="40" v-if="customEventItem.shipmentCustomEventTypeId == 7 && customEventItem.status =='Completed'" >
                <template v-slot:activator="{ on, attrs }" >
                    <v-text-field
                        v-model="customEventItem.completed"
                        label="Completed Date"
                        outlined dense
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="event"
                        :required="customEventItem.status =='Completed'"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="customEventItem.completed" @input="menuCompleted = false"></v-date-picker>
            </v-menu> -->


              <v-text-field id="event-description"
                v-if="customEventItem.shipmentCustomEventTypeId && customEventItem.shipmentCustomEventTypeId != 7"
                outlined dense v-model="customEventItem.reference" placeholder="Reference"
                prepend-icon="description"></v-text-field>
              <v-textarea id="event-comment"
                v-if="customEventItem.shipmentCustomEventTypeId && !customEventItem.shipmentCustomEventType.eventCode"
                outlined rows="4" v-model="customEventItem.comment" placeholder="Comment" prepend-icon="comment"
                dense></v-textarea>
              <div v-if="customEventItem.status == 'Rejected' || customEventItem.status == 'REJECTED'">
                <v-text-field id="event-rejected-reason"
                  v-if="customEventItem.shipmentCustomEventTypeId && !customEventItem.shipmentCustomEventType.eventCode"
                  outlined dense v-model="customEventItem.rejectReason" placeholder="Reason Rejected"
                  prepend-icon="comments_disabled"></v-text-field>
                <v-text-field id="event-fault-party"
                  v-if="customEventItem.shipmentCustomEventTypeId && !customEventItem.shipmentCustomEventType.eventCode"
                  outlined dense v-model="customEventItem.faultParty" placeholder="Fault Party"
                  prepend-icon="dangerous"></v-text-field>

              </div>
            </v-col>

            <v-col
              v-if="customEventItem.shipmentCustomEventTypeId && !customEventItem.shipmentCustomEventType.eventCode">
              <div v-if="customEventItem.shipmentCustomEventTypeId === 1">
                <v-autocomplete id="event-pickup" label="Pickup Location*" :items="organisationAddresses"
                  v-model="customEventItem.pickupAddressId" outlined dense clearable item-text="alias" item-value="id"
                  prepend-icon="home_filled"></v-autocomplete>
                <v-autocomplete id="event-delivery" label="Delivery Location*" :items="organisationAddresses"
                  v-model="customEventItem.deliveryAddressId" outlined dense clearable item-text="alias" item-value="id"
                  prepend-icon="home"></v-autocomplete>
              </div>
              <div id="event-sub-date">
                <v-menu v-model="sumbittedMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="customEventItem.submittedAt" label="Submitted Date" prepend-icon="event"
                      readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.submittedAt" @input="sumbittedMenu = false"></v-date-picker>
                </v-menu>
              </div>
              <div id="event-resub-date">
                <v-menu v-model="resumbittedMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="customEventItem.resubmittedAt" label="Resubmitted Date" prepend-icon="event"
                      readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.resubmittedAt"
                    @input="resumbittedMenu = false"></v-date-picker>
                </v-menu>
              </div>
              <div id="event-rejected-date">
                <v-menu v-model="rejectedMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="customEventItem.rejectedAt" label="Rejected Date" prepend-icon="event"
                      readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.rejectedAt" @input="rejectedMenu = false"></v-date-picker>
                </v-menu>
              </div>
              <div id="event-completed-date">
                <v-menu v-model="completedMenu" :close-on-content-click="false" :nudge-right="40"
                  transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="customEventItem.completedAt" label="Completed Date" prepend-icon="event"
                      readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
                  </template>
                  <v-date-picker v-model="customEventItem.completedAt" @input="completedMenu = false"></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <v-row class="mt-2" justify="center">
            <v-btn @click="deleteCustomEvent()" :loading="deletingCustomEvent" v-if="customEventItem.id" color="red"
              style="text-transform: none"><v-icon>delete</v-icon> Delete</v-btn>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="serviceFailureModal" persistent width="400px" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar flat color="transparent"><v-toolbar-title>Service Failure</v-toolbar-title> <v-spacer></v-spacer>
          <v-btn id="info-guide" @click="sfStartGuide" fab height="4vh" width="4vh">
            <v-icon>help</v-icon>
          </v-btn>
          <v-btn id="service-failure-save" color="primary" icon :loading="savingServiceFailure"
            :disabled="!serviceFailureItem.serviceFailureCategoryId || (!serviceFailureItem.responsibleOrganisationId && !serviceFailureItem.responsibleParty)"
            @click="saveServiceFailure"><v-icon>save</v-icon></v-btn>
          <v-btn text @click="serviceFailureModal = false, serviceFailureItem = {}">X</v-btn></v-toolbar>
        <v-card-text>
          <v-autocomplete id="service-failure-category" label="Category*" :items="serviceFailureCategories"
            v-model="serviceFailureItem.serviceFailureCategoryId" outlined dense clearable item-text="name"
            item-value="id"></v-autocomplete>
          <v-autocomplete id="service-failure-responsible-party" :items="availableParties"
            v-model="serviceFailureItem.responsibleOrganisationId" label="Responsible Party" outlined dense clearable
            item-text="name" item-value="value"></v-autocomplete>
          <v-text-field id="service-failure-specific-party" v-if="!serviceFailureItem.responsibleOrganisationId"
            v-model="serviceFailureItem.responsibleParty" outlined dense clearable label="Specify Party"></v-text-field>
          <v-textarea id="service-failure-description" outlined rows="8" v-model="serviceFailureItem.comment"
            placeholder="Description"></v-textarea>
          <v-row class="mt-2" justify="center">
            <v-btn id="service-failure-delete" @click="deleteServiceFailure()" :loading="deletingServiceFailure"
              v-if="serviceFailureItem.id && serviceFailureItem.organisationId == $store.state.currentOrg.id"
              color="red" style="text-transform: none"><v-icon>delete</v-icon> Delete</v-btn>

          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="infoStart" max-width="31vw">
      <v-card>
        <v-btn @click="infoStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Shipment Overview!
        </v-card-title>
        <v-card-text>
          <span>
            <p>In this section, you will access detailed information and corresponding documentation related to the
              specific shipment you have selected. The information is organized into different subcategories and
              corresponding tabs or screens.
            </p>
            <p>This screen is revering to Overview information, containing the following
            <ul>
              <li>Shipment Header: Main parties to the shipment and vessel name and routing information.</li>
              <li>Overview: For internal use, a summary statusses the client, documetation and system.</li>
              <li>Shipment Overview: Client overview of documentation, tracking, cargo summary and other comments.</li>
              <li>Containers: Per container breakdown of containers or cargo units.</li>
              <li>Documentation: All documents required for the shipment and corresponding statusses.</li>
              <li>Uploads: Copies of the actual documents relevant to the shipment.</li>
              <li>Shipment Profile: Overview of all parties related to this shipment.</li>
            </ul>
            </p>
            To begin, click on the START button below. We will then guide you through additional tips and explanations.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="csStart" max-width="31vw">
      <v-card>
        <v-btn @click="csStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Overview - Custom Events!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startCSGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sfStart" max-width="31vw">
      <v-card>
        <v-btn @click="sfStartGuide" icon style="position: absolute; right: 0; margin: 0.6vh 1vh 0 0;"><v-icon
            color="primary" size="30">close</v-icon></v-btn>
        <v-card-title class="d-flex justify-center">
          FRIKO Welcome to Overview - Service Failure!
        </v-card-title>
        <v-card-text>
          <span>
            <p>FRIKO</p>
            <p>FRIKO
              <br>• Map out routes for transporters
              <br>• Add GeoJSON data to locations used determine if a vehicle is within a certain area
              <br>• Calculate accurate waiting times at loading points
              <br>• Calculate distances for billing purposes
              <br>• Pin-point locations for the purpose of reporting and analysis
              <br>• Map out farms that aren't found on Google Maps
            </p>
            If you would like learn how to load POI's into the system, click the button below to start the
            tutorial.
          </span>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="startSFGuide" color="edit" class="mb-3">Start!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Help Guide/ Tutorial -->
    <v-btn id="info-guide" @click="infoStartGuide" fab height="4vh" width="4vh"
      style="position: absolute; z-index: 5; right: 1vw; top: 1vh">
      <v-icon>help</v-icon>
    </v-btn>
    <v-dialog v-model="shipmentChangeModal" :max-width="shipmentChangeType == 'CHANGE_PROFILE' ? '1400px' : '750px'"
      :fullscreen="$vuetify.breakpoint.mobile" persistent>
      <ShipmentChange :shipment="shipment" :containers="bookings" :type="shipmentChangeType"
        @close="shipmentChangeModal = false" :documents="documents" :customerId="customerId"
        :relationships="relationships" @refresh="$emit('refresh'), shipmentChangeModal = false" />
    </v-dialog>

    <ShippingInstructionDialog ref="shippingInstructionDialog" />
  </div>
</template>
<script>
import dateFormat from "dateformat";
import EventModal from "@/components/SystemComponents/EventModal.vue";
import ShippingInstructionDialog from "@/components/Integrations/ShippingInstructions/ShippingInstructionDialog.vue";
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import overviewSteps from './Steps/overviewSteps'
import csSteps from './Steps/customEventDialogSteps'
import sfSteps from './Steps/serviceFailureSteps'
import { SI_INTEGRATION_CODES, SI_TEST_USERS_IDS, SI_FORM_ACTION_TYPES } from "@/utils/constants";
import ShipmentChange from "./ShipmentChange.vue";

export default {
  props: [
    "shipment",
    "actions",
    "bookings",
    "customerId",
    "documents",
    "events",
    "shipmentProducts",
    "loadingProducts",
    "relationships",
    "tasks",
    "uploads"
  ],
  components: {
    EventModal,
    ShippingInstructionDialog,
    ShipmentChange
  },
  data: () => ({
    changeShipmentUCRModal: false,
    savingShipmentUCR: false,
    menuSubmitted: false,
    menuCompleted: false,
    menuResubmitted: false,
    customerName: null,
    activeBillingTab: 0,
    billableDocuments: [],
    commentKey: 0,
    comment: '',
    comments: [],
    completedMenu: false,
    event: {},
    eventModal: false,
    customEventItem: {},
    customEvents: [],
    customEventModal: false,
    customEventTypes: [],
    organisationAddresses: [],
    loadingCustomEvents: false,
    savingCustomEvent: false,
    deletingCustomEvent: false,
    customEventStatuses: [
      "Not started",
      "Pending",
      "Submitted",
      "Resubmitted",
      "Rejected",
      "Completed",
    ],
    invoiceStatusOptions: [
      { name: 'Not Ready', value: 'NOTREADY', color: 'grey', icon: 'schedule' },
      { name: 'Ready to Invoice', value: 'READY', color: '#DC7633', icon: 'pending' },
      { name: 'Invoiced', value: 'INVOICED', color: '#5DADE2 ', icon: 'paid' },
      { name: 'Paid', value: 'PAID', color: '#229954', icon: 'task_alt' },
    ],
    loadingBillableDocuments: false,
    loadingComments: false,
    loadingServiceFailures: false,
    massActions: [],
    resettingTasks: false,
    savingComment: false,
    savingInvoiceStatus: false,
    deletingServiceFailure: false,
    serviceFailureCategories: [],
    serviceFailures: [],
    serviceFailureModal: false,
    serviceFailureItem: {},
    savingServiceFailure: false,
    sumbittedMenu: false,
    rejectedMenu: false,
    resumbittedMenu: false,
    infoStart: false,
    csStart: false,
    sfStart: false,
    orgAdresses: [],
    shipmentChangeModal: false,
    shipmentChangeType: null,
    SI_INTEGRATION_CODES,
    hookingShipment: false
  }),
  watch: {
    'customEventItem.courier': {
      handler: function (newCourier, oldCourier) {
        if (newCourier !== oldCourier) {
          this.fetchOrgAddresses(newCourier);
        }
      },
      immediate: true, // to run the handler immediately after the watcher is created
    },
    'customEventItem.shipmentCustomEventTypeId': {
      immediate: true,
      handler(val) {
        if (val) {
          let find = this.customEventTypes.find(x => x.id == val)
          if (find) {
            this.customEventItem.shipmentCustomEventType = find
          }
        } else {
          this.customEventItem.shipmentCustomEventType = {}
        }
      }
    }
  },
  computed: {
    canPreviewSI() {
      const isSystemAdmin = [1, 2].includes(this.$store.state.user.tagId);
      if (isSystemAdmin) return true;

      const hardcodedUserIds = SI_TEST_USERS_IDS; //TODO - Remove, only for testing
      if (hardcodedUserIds.includes(this.$store.state.user.id)) return true;

      return false;
    },

    availableParties() {
      let result = []
      let parties = ['shipper', 'onBehalfShipper', 'forwarder', 'onBehalfForwarder', 'consignee', 'onBehalfConsignee', 'buyer', 'onBehalfBuyer', 'firstNotify', 'secondNotify', 'courierParty']
      for (let i = 0; i < parties.length; i++) {
        if (this.shipment[parties[i]]) {
          let findExisting = result.find(x => x.value == this.shipment[parties[i] + 'Id'])

          if (!findExisting) {
            result.push({
              name: this.shipment[parties[i]].name,
              value: this.shipment[parties[i] + 'Id']
            })
          }
        }
      }
      if (this.relationships && this.relationships.length > 0) {
        for (let i = 0; i < this.relationships.length; i++) {
          let findExisting = result.find(x => x.value == this.relationships[i].customerId)
          if (!findExisting) {
            result.push({
              name: this.relationships[i].customer.name,
              value: this.relationships[i].customerId
            })
          }
        }
      }
      result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
      // result.push({ name: 'Other', value: null })
      return result

    },
    accountDocuments() {
      let result = this.summarisedDocuments
      return result
    },
    containerDocuments() {
      let result = this.documents.filter((x) => x.bookingContainerId);
      let unique = [...new Set(result.map((x) => x.locationDocumentId))];
      result = unique.map((x) => ({
        ...result.find((y) => y.locationDocumentId == x),
        requiredCount: result
          .filter((y) => y.locationDocumentId == x)
          .map((y) => y.requiredCount)
          .reduce((a, b) => a + b, 0),
        submittedCount: result
          .filter((y) => y.locationDocumentId == x)
          .map((y) => y.submittedCount)
          .reduce((a, b) => a + b, 0),
      }));
      return result;
    },
    dangerCount() {
      let result = this.summarisedDocuments.filter((x) =>
        this.documentDanger(x)
      );
      return result.length;
    },
    documentSpecialComments() {
      let filter = this.documents.filter(x => x.comment || x.specialInstruction)
      let uniqueDocuments = [
        ...new Set(
          filter
            .map((x) => x.locationDocumentId)
        )
      ];
      let result = []
      for (let i = 0; i < uniqueDocuments.length; i++) {
        let document = filter.find(x => x.locationDocumentId == uniqueDocuments[i])
        let obj = {
          id: document.id,
          name: document.locationDocument.name,
          comment: document.comment,
          specialInstruction: document.specialInstruction,
        };
        result.push(obj);
      }
      return result
    },
    warningCount() {
      let result = this.summarisedDocuments.filter((x) =>
        this.documentWarning(x)
      );
      return result.length;
    },
    pallets() {
      let pallets = [].concat.apply(
        [],
        this.bookings.map((x) => x.containerPallets)
      );

      return pallets;
    },

    progressPercentage() {
      let allDocsTotal = this.summarisedDocuments
        .map((x) => x.count)
        .reduce((a, b) => a + b, 0);
      let completed = this.summarisedDocuments.filter((x) =>
        x.statuses.find((y) => y.status == "Complete")
      );
      return completed.length + " / " + allDocsTotal;
    },

    shipmentDocuments() {
      return this.documents.filter((x) => !x.bookingContainerId);
    },
    summarisedDocuments() {
      let result = [];
      let statuses = ["Pending", "In Progress", "Submitted", "File Generated", "Complete"];

      let uniqueDocuments = [
        ...new Set(
          this.documents
            .filter((x) => statuses.includes(x.status))
            .map((x) => x.locationDocumentId)
        )
      ];

      for (let i = 0; i < uniqueDocuments.length; i++) {
        let document = this.documents.find(
          (x) => x.locationDocumentId == uniqueDocuments[i]
        );

        let obj = {
          id: document.id,
          name: document.locationDocument.name,
          locationDocumentId: document.locationDocumentId,
          count: this.documents.filter(
            (x) => x.locationDocumentId == uniqueDocuments[i]
          ).length,
          completedDue: document.completedDue,
          submittedDue: document.submittedDue,
          statuses: statuses
            .map((x, index) => ({
              index: index,
              status: x,
              count: this.documents.filter(
                (y) =>
                  y.locationDocumentId == uniqueDocuments[i] && y.status == x
              ).length,
            }))
            .filter((x) => x.count > 0),
        };
        result.push(obj);
      }
      return result;
    },
    validateCustomEvent() {
      let res = true
      if (this.customEventItem.shipmentCustomEventTypeId) {
        if (this.customEventItem.shipmentCustomEventTypeId === 1) {
          if (this.customEventItem.pickupAddressId && this.customEventItem.deliveryAddressId) {
            res = false
          } else {
            res = true
          }
        } else {
          res = false
        }
      }
      return res
    }
  },
  async mounted() {
    this.driver = new Driver({
      animate: false
    })
  },
  created() {
    this.getCustomEvents()
    this.getBillableDocuments()
    this.getServiceFailures()
    this.getServiceFailureCategories()

    if (this.shipment.freightCharge === 'customer') {
      this.shipmentCustomer()
    }
  },
  methods: {
    async hookShipment(){
      this.hookingShipment = true
      await this.$API.hookShipmentDetails(this.shipment.id)
      this.hookingShipment = false
    },
    async fetchOrgAddresses() {
      console.log('this.customEventItem.courier', this.customEventItem.courier)
      let res = await this.$API.getAllOrgAdresses(this.customEventItem.courier)
      this.orgAdresses = res
    },
    async shipmentCustomer() {
      let shipmentId = this.shipment.id
      let resData = await this.$API.getShipmentRelationships(shipmentId)
      // console.log('resData',resData[0].customer.name)
      let res = resData[0].customer.name
      this.customerName = res
    },
    addProduct() {
      this.productitem = {
        shipmentId: this.shipment.id,
      };
      this.productModal = true;
    },
    async getServiceFailureCategories() {
      this.serviceFailureCategories = await this.$API.getServiceFailureCategories();
    },
    async getServiceFailures() {
      this.loadingServiceFailures = true
      this.serviceFailures = await this.$API.getServiceFailureByShipment(this.shipment.id)
      this.loadingServiceFailures = false
    },
    directNewDocumentation(){
      this.$router.push({
        path: "/documentation/"+this.shipment.shipmentReference
      })
    },
    editServiceFailure(failure) {
      if (failure.type === 'SHIPMENT') {
        this.serviceFailureItem = failure
        this.serviceFailureModal = true
      } else {
        this.$message.error("You are only able to manage document service failures under the 'Documents' tab.");
      }
    },
    addServiceFailure() {
      this.serviceFailureItem = {
        responsibleOrganisationId: this.$store.state.currentOrg.id
      }
      this.serviceFailureModal = true;
    },
    async saveServiceFailure() {
      this.savingServiceFailure = true;
      if (this.serviceFailureItem.id) {
        await this.$API.updateServiceFailure(this.serviceFailureItem)
      } else {
        this.serviceFailureItem.type = 'shipment'
        this.serviceFailureItem.shipmentId = this.shipment.id
        await this.$API.createServiceFailure(this.serviceFailureItem);
      }
      this.getServiceFailures()
      this.serviceFailureModal = false;
      this.serviceFailureItem = {};
      this.savingServiceFailure = false;
    },
    deleteServiceFailure() {
      this.deletingServiceFailure = true
      this.$confirm(
        "Are you sure you want to delete this service failure?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.serviceFailureItem.isDeleted = true;
          this.serviceFailureItem.isActive = false;
          await this.$API.updateServiceFailure(this.serviceFailureItem);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.getServiceFailures()
          this.serviceFailureModal = false
          this.serviceFailureItem = {}
          this.deletingServiceFailure = false
        })
        .catch(() => {
          this.deletingServiceFailure = false
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    billableEvents() {
      let uniqueDocuments = [...new Set(this.billableDocuments.map(x => x.shipmentDocumentTypeId))]
      let result = []
      for (let i = 0; i < uniqueDocuments.length; i++) {
        let uniqueStatuses = [...new Set(this.billableDocuments.filter(x => x.shipmentDocumentTypeId == uniqueDocuments[i]).map(x => x.status))]
        let find = this.documents.find(x => x.id == uniqueDocuments[i])
        if (find) {
          let obj = {
            id: find.id,
            name: find.locationDocument.name,
            statuses: []
          }
          for (let j = 0; j < uniqueStatuses.length; j++) {
            obj.statuses.push({
              id: i * 1000 + j,
              name: uniqueStatuses[j],
              count: this.billableDocuments.filter(x => x.shipmentDocumentTypeId == uniqueDocuments[i] && x.status == uniqueStatuses[j]).length
            })
          }
          result.push(obj)
        }
        return result
      }
    },
    billableEventsByDocument(document) {
      let filteredDocuments = this.documents.filter(x => x.locationDocumentId == document.locationDocumentId)
      let uniqueStatuses = [...new Set(this.billableDocuments.map(x => x.status))]
      let result = []
      for (let i = 0; i < uniqueStatuses.length; i++) {
        let filtered = this.billableDocuments.filter(x => filteredDocuments.map(y => y.id).includes(x.shipmentDocumentTypeId) && x.status == uniqueStatuses[i])
        let obj = {
          id: i,
          status: uniqueStatuses[i],
          items: filtered,
          count: filtered.length
        }
        if (obj.count > 0)
          result.push(obj)
      }
      return result
    },
    calculateProductCartons(productId) {
      let pallets = this.pallets.filter((x) => x.productId == productId && x.packType == "CARTON");
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductBins(productId) {
      let bins = this.pallets.filter((x) => x.productId == productId && x.packType == "BIN");
      return bins.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateProductNettWeight(productId) {
      let pallets = this.pallets.filter((x) => x.productId == productId);
      return pallets.reduce((a, b) => a + parseFloat(b.nettWeight), 0).toFixed(2);
    },
    calculateVarietyProductNettWeight(varietyId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == varietyId);
      return pallets.reduce((a, b) => a + parseFloat(b.nettWeight), 0).toFixed(2);
    },
    calculateProductGrossWeight(productId) {
      let pallets = this.pallets.filter((x) => x.productId == productId);
      return pallets.reduce((a, b) => a + parseFloat(b.grossWeight), 0).toFixed(2);
    },
    calculateVarietyProductCartons(productId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == productId);
      return pallets.reduce((a, b) => a + parseInt(b.noCartons), 0);
    },
    calculateVarietyProductGrossWeight(productId) {
      let pallets = this.pallets.filter((x) => x.varietyProductId == productId);
      return pallets.reduce((a, b) => a + parseFloat(b.grossWeight), 0).toFixed(2);
    },
    completeForm(action) {
      this.form = action;
      this.form.form.formResponse = action.response;
      this.formKey++;
      this.formPreview = true;
    },

    documentWarning(document) {
      let result = false;
      let today = new Date();
      let submittedWarningDate = new Date(document.submittedDue).setDate(
        new Date(document.submittedDue).getDate() - 2
      );
      let findSubmitted = document.statuses.find(
        (x) => x.status == "Pending" || x.status == "In Progress"
      );
      if (findSubmitted) {
        result = today >= submittedWarningDate;
      }
      let completedWarningDate = new Date(document.completedDue).setDate(
        new Date(document.completedDue).getDate() - 2
      );
      let findCompleted = document.statuses.find(
        (x) =>
          x.status == "Pending" ||
          x.status == "In Progress" ||
          x.status == "Submitted"
      );
      if (findCompleted) {
        result = today >= completedWarningDate;
      }
      if (result) {
        result = !this.documentDanger(document);
      }
      return result;
    },

    documentDanger(document) {
      let result = false;
      let today = new Date();
      let completedWarningDate = new Date(document.completedDue).setDate(
        new Date(document.completedDue).getDate()
      );
      let findCompleted = document.statuses.find(
        (x) =>
          x.status == "Pending" ||
          x.status == "In Progress" ||
          x.status == "Submitted"
      );
      if (findCompleted) {
        result = today >= completedWarningDate;
      }
      return result;
    },


    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getBillableDocuments() {
      this.loadingBillableDocuments = true
      this.billableDocuments = await this.$API.getBillableShipmentDocumentTypeLog(this.shipment.id)
      this.loadingBillableDocuments = false
    },
    getInvoiceIcon(status) {
      let find = this.invoiceStatusOptions.find(x => x.value == status);
      return find ? find.icon : 'schedule'
    },
    getInvoiceColor(status) {
      let find = this.invoiceStatusOptions.find(x => x.value == status);
      return find ? find.color : 'grey'
    },
    getInvoiceName(status) {
      let find = this.invoiceStatusOptions.find(x => x.value == status);
      return find ? find.name : null
    },
    getStatusColor(status) {
      switch (status.toLowerCase()) {
        case "open":
          return "grey";
        case "not required":
          return "grey";
        case "not started":
          return "grey";
        case "approved":
          return "green";
        case "complete":
          return "green";
        case "in progress":
          return "indigo";
        case "submitted":
          return "blue";
        case "file generated":
          return "orange";
        case "pending":
          return "orange";
        case status.includes("pending"):
          return "orange";
        case "draft approved":
          return "purple";
        case "revision required":
          return "red";
        case "error":
          return "red";
        default:
          return "grey";
      }
    },
    getStatusIcon(status) {
      switch (status.toLowerCase()) {
        case "not required":
          return "close";
        case "not started":
          return "schedule";
        case "pending":
          return "pending";
        case "in progress":
          return "sync";
        case "submitted":
          return "mark_email_read";
        case "complete":
          return "task_alt";
        case "file generated":
          return "task";
        case "error":
          return "cancel";
        default:
          return "info";
      }
    },
    getStatus(status) {
      switch (status) {
        case "Not started":
          return { name: status, icon: "schedule", color: "grey" };
        case "Pending":
          return { name: status, icon: "pending", color: "orange" };
        case "In Progress":
          return { name: status, icon: "sync", color: "blue" };
        case "Delivered":
          return { name: status, icon: "task_alt", color: "green" };
        case "In Transit":
          return { name: status, icon: "local_shipping", color: "blue" };
        case "Complete":
          return { name: status, icon: "task_alt", color: "green" };
        case "Completed":
          return { name: status, icon: "task_alt", color: "green" };
        case "File Generated":
          return { name: status, icon: "task", color: "orange" };
        case "Success":
          return { name: status, icon: "task_alt", color: "green" };
        case "Cancelled":
          return { name: status, icon: "cancel", color: "grey" };
        case "Draft":
          return { name: status, icon: "edit_note", color: "orange" };
        case "Error":
          return { name: status, icon: "error", color: "red" };
        case "Failed":
          return { name: status, icon: "cancel", color: "red" };
        case "Rejected":
          return { name: status, icon: "cancel", color: "red" };
        case "Submitted":
          return { name: status, icon: "mark_email_read", color: "blue" };
        case "Created":
          return { name: status, icon: "mark_email_read", color: "blue" };
        case "Resubmitted":
          return { name: status, icon: "mark_email_read", color: "orange" };
        case "Not Required":
          return { name: status, icon: "close", color: "grey" };
        case "Approved":
          return { name: status, icon: "fact_check", color: "green" };
        default:
          return {
            icon: "info",
            color: "grey",
            name: status,
          };
      }
    },
    getTeamMemberCount(id) {
      let findTeam = this.allTeams.find(team => team.id == id)
      return findTeam ? findTeam.members : 0
    },
    processChange(type) {
      this.shipmentChangeType = type;
      this.shipmentChangeModal = true;
    },
    async refreshShipmentDocuments() {
      this.$confirm(
        "Are you sure you want to regenerate all documents for this file? This action cannot be reversed.",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      ).then(async () => {
        this.shipment.fileStatus = "In Progress";
        let result = await this.$API.refreshShipmentDocuments(this.shipment.id);
        this.$message.success("Successfully started regenerating documents");
      });
    },

    async resetFileSync() {
      this.$confirm(
        "Are you sure you want to re-sync the details for this file?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      ).then(async () => {
        let result = await this.$API.resetShipmentFileSync(this.shipment.shipmentFile.id);
        this.shipment.shipmentFile.synced = 0
        this.$message.success("Successfully reset sync flag for file");
      });
    },
    async resetAllTasks() {
      this.resettingTasks = true;
      await this.$API.resetAllShipmentTasks(this.shipment.id);
      this.$emit('refreshTasks')
      this.$message.success("Successfully reset all tasks");
      this.resettingTasks = false;
    },
    async refreshEvents() {
      this.eventModal = false
      this.event = {}
      this.$emit('refreshEvents')
    },
    updateEvent(event) {
      this.event = event;
      this.$emit("updateEvent", event);
    },
    async updateInvoiceStatus(relationship) {
      this.savingInvoiceStatus = true;
      await this.$API.updateShipmentRelationship(relationship)
      this.$message.success("Successfully updated invoice status");
      this.savingInvoiceStatus = false;
    },
    viewEvent(event) {
      this.event = event;
      this.eventModal = true;
    },
    async getCustomEvents() {
      this.loadingCustomEvents = true
      this.customEvents = await this.$API.getShipmentCustomEvents(this.shipment.id)
      this.customEventTypes = await this.$API.getCustomEventTypes()
      this.organisationAddresses = await this.$API.getOrganisationAddress()
      this.loadingCustomEvents = false
    },
    async saveCustomEvent() {
      this.savingCustomEvent = true

      if (this.customEventItem && this.customEventItem.courier) {
        console.log('this.customEventItem.courier', this.customEventItem.destinationAddressId)
        if (!this.customEventItem.destinationAddressId) {
          let destinationId = await this.$API.getSingleOrganisionAdress(this.customEventItem.courier)
          this.customEventItem.destinationAddressId = destinationId.id
        }
        console.log('this.customEventItem.destinationAddressId', this.customEventItem.destinationAddressId);
        let originId = await this.$API.getSingleUserOrgAdress()
        console.log('originId', originId)
        this.customEventItem.originAddressId = originId.organisationAddressId
      }
      if (this.customEventItem.shipmentCustomEventType && this.customEventItem.shipmentCustomEventType.eventCode) {
        this.customEventItem.eventCode = this.customEventItem.shipmentCustomEventType.eventCode
        this.customEventItem.shipmentId = this.shipment.id
        if (!this.customEventItem.status || this.customEventItem.status == '') { this.customEventItem.status = 'Not Started' }

        if (this.customEventItem.shipmentCustomEventType.eventCode == 'ZA_DHL_Waybill') {
          let res = await this.$API.createManualShipmentDocument({
            shipmentId: this.shipment.id,
            eventCode: this.customEventItem.shipmentCustomEventType.eventCode,
            quantityType: 'Shipment',
            submitted: this.customEventItem.submitted,
            resubmitted: this.customEventItem.resubmitted,
            completed: this.customEventItem.completed,
            eventStatus: this.customEventItem.status,
          })
          // console.log('res.data',res.data[0].id)
          this.customEventItem.shipmentDocumentTypeId = res.data[0].id
          this.customEventItem.manuallyCreated = true
          this.custom
        }
        await this.$API.createShipmentEvent(this.customEventItem)
        this.$emit('refreshEvents')

      } else {
        this.customEventItem.shipmentId = this.shipment.id
        this.customEventItem.createdById = this.$store.state.user.id
        this.customEventItem.status = this.setCustomEventStatus()
        // if(!this.customEventItem.status || this.customEventItem.status == ''){this.customEventItem.status = 'Pending'}
        if (this.customEventItem && this.customEventItem.id) {
          this.customEventItem = await this.$API.updateShipmentCustomEvent(this.customEventItem, this.customEventItem.id)
        } else {
          this.customEventItem = await this.$API.createShipmentCustomEvent(this.customEventItem)
        }
        this.getCustomEvents()
      }

      this.savingCustomEvent = false
      this.customEventItem = {}
      this.customEventModal = false
    },
    async deleteCustomEvent() {
      this.deletingCustomEvent = true
      this.$confirm(
        "Are you sure you want to delete this event?",
        "Confirm",
        {
          center: true,
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "plain",
        }
      )
        .then(async () => {
          this.customEventItem.isDeleted = true;
          this.customEventItem.isActive = false;
          await this.$API.updateShipmentCustomEvent(this.customEventItem, this.customEventItem.id);
          this.$message({
            type: "success",
            message: "Successfully deleted!",
          });
          this.getCustomEvents()
          this.customEventModal = false
          this.customEventItem = {}
          this.deletingCustomEvent = false
        })
        .catch(() => {
          this.deletingCustomEvent = false
          this.$message({
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    editCustomEvent(event) {
      this.customEventItem = event
      this.customEventModal = true
    },
    setCustomEventStatus() {
      let status = this.customEventItem.status
      if (this.customEventItem.submittedAt) {
        status = 'Submitted'
        if (this.customEventItem.shipmentCustomEventTypeId == 3) {
          this.customEventItem.completedAt = this.customEventItem.submittedAt
          status = 'Completed'
        }
      }
      if (this.customEventItem.resubmittedAt) {
        status = 'Resubmitted'
      }
      if (this.customEventItem.completedAt) {
        status = 'Completed'
      }
      return status;
    },
    addCustomEvent() {
      this.customEventModal = true
    },
    infoStartGuide() {
      if (this.infoStart) {
        this.infoStart = false;
      } else {
        this.infoStart = true;
      }
    },
    startGuide(e) {
      this.infoStart = false
      e.stopPropagation();
      this.driver.defineSteps(overviewSteps)
      this.driver.start()
    },
    csStartGuide() {
      if (this.csStart) {
        this.csStart = false;
      } else {
        this.csStart = true;
      }
    },
    routeToContact(contact) {
      console.log(contact)
      this.$router.push('/contact/' + contact.id)
    },
    startCSGuide(e) {
      this.csStart = false
      e.stopPropagation();
      this.driver.defineSteps(csSteps)
      this.driver.start()
    },
    sfStartGuide() {
      if (this.sfStart) {
        this.sfStart = false;
      } else {
        this.sfStart = true;
      }
    },
    startSFGuide(e) {
      this.sfStart = false
      e.stopPropagation();
      this.driver.defineSteps(sfSteps)
      this.driver.start()
    },
    viewShippingInstruction() {
      this.$refs.shippingInstructionDialog.open({
        intergrationCode: SI_INTEGRATION_CODES.INTTRA,
        shipmentId: this.shipment.shipmentReference,
        actionType: SI_FORM_ACTION_TYPES.INTERNAL
      })
    },
    async saveShipmentUCR() {
      this.savingShipmentUCR = true;
      let obj = {
        id: this.shipment.id, 
        ucrNo: this.shipment.ucrNo
      }
      await this.$API.saveShipmentUCR(obj);
      this.savingShipmentUCR = false;
      this.changeShipmentUCRModal = false;
      this.$message.success("Successfully updated UCR number!");
    }
  },
};
</script>

<style scoped>
.pulse-danger {
  animation: pulse-danger 1.5s infinite;
  z-index: 10;
}

@keyframes pulse-danger {
  0% {
    border-radius: 50%;
    box-shadow: 0 0 0 0px red;
  }

  100% {
    border-radius: 50%;
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }
}

.pulse-warning {
  animation: pulse-warning 1.5s infinite;
  z-index: 10;
}

@keyframes pulse-warning {
  0% {
    border-radius: 50%;
    box-shadow: 0 0 0 0px orange;
  }

  100% {
    border-radius: 50%;
    box-shadow: 0 0 0 20px rgb(255, 165, 0, 0);
  }

}

.scroll {
  overflow-y: scroll
}</style>
