const steps = [
  {
    element: "#system-actions",
    popover: {
      title: "System Actions",
      description:
        "This section of the overview informs you about all the system actions and their statuses",
      position: "top",
    },
  },
  {
    element: "#overview-events",
    popover: {
      title: "Events",
      description:
        "This section of the overview informs you about all the events and their statuses",
      position: "top",
    },
  },
  {
    element: "#special-instructions",
    popover: {
      title: "Special Instructions",
      description:
        "This section of the overview informs you about information regarding any special instructions",
      position: "top",
    },
  },
  {
    element: "#customer-management",
    popover: {
      title: "Customer Management",
      description:
        "This section of the overview informs you about information regarding any customer management info",
      position: "top",
    },
  },
  {
    element: "#service-failures",
    popover: {
      title: "Service Failures",
      description:
        "This section of the overview informs you about information regarding any service failure info",
      position: "top",
    },
  },
  {
    element: "#account-summary",
    popover: {
      title: "Account Summary",
      description:
        "This section of the overview informs you about information regarding any account summary info",
      position: "top",
    },
  },
  {
    element: "#overview-documents",
    popover: {
      title: "Documents",
      description:
        "This section of the overview informs you about information regarding any document info",
      position: "top",
    },
  },
];

export default steps;
