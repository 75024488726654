const steps = [
  {
    element: "#service-failure-category",
    popover: {
      title: "Service Failure - Category",
      description: "Choose the cateogory for this new service failure",
      position: "left",
    },
  },
  {
    element: "#service-failure-responsible-party",
    popover: {
      title: "Service Failure - Responsible Party",
      description: "Choose the responsible party for this new service failure",
      position: "left",
    },
  },
  {
    element: "#service-failure-specific-party",
    popover: {
      title: "Service Failure - Specific Party",
      description: "Choose the specific party for this new service failure",
      position: "left",
    },
  },
  {
    element: "#service-failure-description",
    popover: {
      title: "Service Failure - Description",
      description: "State the description for this new service failure",
      position: "left",
    },
  },
  {
    element: "#service-failure-delete",
    popover: {
      title: "Service Failure - Delete",
      description: "Allows you to delete this service failure",
      position: "top",
    },
  },
  {
    element: "#service-failure-save",
    popover: {
      title: "Service Failure - Save",
      description: "Allows you to save this service failure",
      position: "top",
    },
  },
];

export default steps;
