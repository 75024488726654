const steps = [
  {
    element: "#event-type",
    popover: {
      title: "Event - Type",
      description: "This is where you choose the type of the custom event",
      position: "left",
    },
  },
  {
    element: "#event-status",
    popover: {
      title: "Event - Status",
      description: "This is where you set the status of the custom event",
      position: "left",
    },
  },
  {
    element: "#event-description",
    popover: {
      title: "Event - Description",
      description: "This is where you set the description of the custom event",
      position: "left",
    },
  },
  {
    element: "#event-comment",
    popover: {
      title: "Event - Comment",
      description: "This is where comments are set for the custom event",
      position: "left",
    },
  },
  {
    element: "#event-rejected-reason",
    popover: {
      title: "Event - Rejected Reason",
      description:
        "This is where comments are set the reason for the rejected status",
      position: "left",
    },
  },
  {
    element: "#event-fault-party",
    popover: {
      title: "Event - Fault Party",
      description:
        "This is where you set who the fault party for the rejected custom event",
      position: "left",
    },
  },
  {
    element: "#event-pickup",
    popover: {
      title: "Event - Pick Up Location",
      description:
        "This is where you set the pick up location for the custom event",
      position: "right",
    },
  },
  {
    element: "#event-delivery",
    popover: {
      title: "Event - Delivery Location",
      description:
        "This is where you set the delivery location for the custom event",
      position: "right",
    },
  },
  {
    element: "#event-sub-date",
    popover: {
      title: "Event - Submitted Date",
      description:
        "This is where you set the submitted date of the custom event",
      position: "right",
    },
  },
  {
    element: "#event-resub-date",
    popover: {
      title: "Event - Resubmitted Date",
      description:
        "This is where you set the resubmitted date of the custom event",
      position: "right",
    },
  },
  {
    element: "#event-rejected-date",
    popover: {
      title: "Event - Rejected Date",
      description:
        "This is where you set the rejected date of the custom event",
      position: "right",
    },
  },
  {
    element: "#event-completed-date",
    popover: {
      title: "Event - Completed Date",
      description:
        "This is where you set the completed date of the custom event",
      position: "right",
    },
  },
  {
    element: "#event-save",
    popover: {
      title: "Event - Save",
      description:
        "Save the custom event after all the information had been entered",
      position: "top",
    },
  },
];

export default steps;
